import { sessionStorageGet, sessionStorageSet } from "@/stores/utils";
import {
  getBankInformations,
  getDocuments,
  getCurrentPractitioner,
} from "@/services/apiClient/pracOnboarding";
import {
  CountryType,
  KycDocument,
  kycDocumentsTemplate,
  Locales,
  LocaleType,
} from "@/services/pracOnboarding/kycDocuments";
import { Route } from "vue-router/types/router";

const namespace = "pracOnboarding";

export function setToken(route?: Route) {
  if (route && route.query) {
    const { token } = route.query;

    if (token) {
      sessionStorageSet(token as string, namespace, "prac_web_token");
    }
  }
}

export function getToken() {
  return sessionStorageGet(namespace, "prac_web_token") as string;
}

export async function currentPractitioner(
  { forceFetch }: { forceFetch: boolean } = { forceFetch: false }
) {
  var prac = (await sessionStorageGet(namespace, "currentPractitioner")) as any;

  if (!prac || forceFetch) {
    prac = (await getCurrentPractitioner()) as any;
    sessionStorageSet(prac, namespace, "currentPractitioner");
  }

  return prac;
}

export async function documentsState(
  { forceFetch }: { forceFetch: boolean } = { forceFetch: false }
) {
  var documents = (await sessionStorageGet(namespace, "documents")) as any;

  if (!documents || forceFetch) {
    documents = (await getDocuments()) as any;
    sessionStorageSet(documents, namespace, "documents");
  }

  return documents;
}

export async function bankInformation(
  { forceFetch }: { forceFetch: boolean } = { forceFetch: false }
) {
  var bankInformation = (await sessionStorageGet(
    namespace,
    "bankInformation"
  )) as any;

  if (!bankInformation || forceFetch) {
    bankInformation = (await getBankInformations()) as any;
    sessionStorageSet(bankInformation, namespace, "bankInformation");
  }

  return bankInformation;
}

export async function documents(
  { forceFetch }: { forceFetch: boolean } = { forceFetch: false }
): Promise<KycDocument[]> {
  var documentsStates = await documentsState({ forceFetch });
  var bankAccount = await bankInformation({ forceFetch });

  return kycDocumentsTemplate
    .map((kycDocumentTemplate, _index) => {
      var document: KycDocument = {
        ...kycDocumentTemplate,
        uploaded: false,
      };

      if (document.label == "bank_informations") {
        document.uploaded = !!bankAccount;
      } else {
        const currentDocumentState = documentsStates.find((ds: any) => {
          return ds.document_type == document.label;
        });

        if (currentDocumentState == null) {
          return null;
        }

        document.uploaded = [
          "exported",
          "validation_asked",
          "validation_accepted",
        ].includes(currentDocumentState.status);

        if (currentDocumentState.status == "validation_refused") {
          const msg = currentDocumentState.refused_reason;
          const formattedMessage = msg[0].toUpperCase() + msg.substring(1);

          document.refused_reason = formattedMessage;
          if (currentDocumentState?.refused_reason_details) {
            document.refused_reason_details =
              currentDocumentState.refused_reason_details;
          }
        }
      }
      return document;
    })
    .filter((element) => {
      return element !== null;
    }) as KycDocument[];
}

export async function practitionerCountry(
  { forceFetch }: { forceFetch: boolean } = { forceFetch: false }
) {
  var prac = await currentPractitioner({ forceFetch });
  return prac.country.toLowerCase() as CountryType;
}

export async function practitionerLocale(
  { forceFetch }: { forceFetch: boolean } = { forceFetch: false }
) {
  var prac = await currentPractitioner({ forceFetch });
  const locale = Locales.includes(prac.locale) ? prac.locale : "en";
  return locale as LocaleType;
}
