import apiClient, { ApiRequestConfig } from "@/services/apiClient/apiClient";
import { Route } from "vue-router/types/router";
import { getToken } from "../pracOnboarding/dataStorage";

export type PracOnboardingRequestConfig = Omit<ApiRequestConfig, "issuerType">;

export function getEmailVerificationToken(route: Route) {
  if (route && route.query) {
    const { token } = route.query;

    return token as string;
  }
}

export async function getLocale() {
  const jwtToken = getToken();
  const response = await get("/practitioners/current", { jwtToken });
  const { data: currentPrac } = response;

  return currentPrac.locale;
}

export async function getCurrentPractitioner() {
  const jwtToken = getToken();
  const response = await get("/practitioners/current", { jwtToken });

  return response.data;
}

export async function getDocuments() {
  const jwtToken = getToken();
  const response = await get("/practitioners/current/kyc/documents", {
    jwtToken,
  });

  return response.data;
}

export async function verifyEmail(token: string) {
  const response = await post("/practitioners/verify_email", { token });
  const { data: currentPrac } = response;

  return currentPrac;
}

export async function getBankInformations() {
  const jwtToken = getToken();
  const response = await get("/practitioners/current/bank_account", {
    jwtToken,
  });
  const { data: bankAccount } = response;

  return bankAccount;
}

export async function submitBankInformations(
  name: string,
  iban: string,
  bic: string
) {
  const jwtToken = getToken();
  return post(
    "/practitioners/current/bank_account",
    {
      name,
      iban,
      bic,
    },
    {
      jwtToken,
    }
  );
}

export async function submitDocuments(documents: FormData) {
  const jwtToken = getToken();
  return post("/practitioners/current/kyc/documents", documents, { jwtToken });
}

export async function get(url: string, config?: PracOnboardingRequestConfig) {
  let apiConfig: ApiRequestConfig = {
    ...config,
  };

  apiConfig["issuerType"] = "Practitioner";

  if (apiConfig.jwtToken) {
    apiConfig["headers"] = apiConfig["headers"] || {};
    apiConfig.headers["Authorization"] = `Bearer ${apiConfig.jwtToken}`;
  }

  return await apiClient.get(url, apiConfig);
}

export async function post(
  url: string,
  data?: any,
  config?: PracOnboardingRequestConfig
) {
  let apiConfig: ApiRequestConfig = {
    ...config,
  };

  if (apiConfig.jwtToken) {
    apiConfig["headers"] = apiConfig["headers"] || {};
    apiConfig.headers["Authorization"] = `Bearer ${apiConfig.jwtToken}`;
  }
  return await apiClient.post(url, data, apiConfig);
}

export async function put(
  url: string,
  data?: any,
  config?: PracOnboardingRequestConfig
) {
  let apiConfig: ApiRequestConfig = {
    ...config,
  };

  if (apiConfig.jwtToken) {
    apiConfig["headers"] = apiConfig["headers"] || {};
    apiConfig.headers["Authorization"] = `Bearer ${apiConfig.jwtToken}`;
  }

  return await apiClient.put(url, data, apiConfig);
}
