import { Keystore } from "@/stores/types";
import { getEnvVariable } from "@/services/utils/environmentManager";
import { KeyStoreUtils } from "conseiljs-softsigner";
import { mnemonicToSeedSync } from "bip39";
import { TezosToolkit, DEFAULT_FEE } from "@taquito/taquito";
import { InMemorySigner } from "@taquito/signer";
import { RpcClient } from "@taquito/rpc";
import { b58cencode, b58cdecode, prefix, isValidPrefix } from "@taquito/utils";

export interface PackInterface {
  payload: object;
  payloadType: object;
}

export interface CallInterface {
  contractAddress: string;
  parameters: Array<any>;
  keystore: Keystore;
  entrypoint: string;
}

export interface SignInterface {
  payload: string;
  secretKey: string;
}

// Overload the taquito constante
(DEFAULT_FEE as any)["REVEAL"] = 0;

export class BlockchainOperator {
  tezosNode: string;
  private static instance: BlockchainOperator;
  private rpcInstance: RpcClient;
  private taquitoClient: TezosToolkit;

  private constructor(
    tezosNode: string = getEnvVariable("VUE_APP_TEZOS_NODE")
  ) {
    this.tezosNode = tezosNode;
    this.rpcInstance = new RpcClient(this.tezosNode);
    this.taquitoClient = new TezosToolkit(this.tezosNode);
  }

  static getInstance(): BlockchainOperator {
    if (!BlockchainOperator.instance) {
      BlockchainOperator.instance = new BlockchainOperator();
    }

    return BlockchainOperator.instance;
  }

  async formatKeystore(signer: InMemorySigner): Promise<Keystore> {
    const secretKey = await signer.secretKey();
    const publicKey = await signer.publicKey();
    const publicKeyHash = await signer.publicKeyHash();

    const keystore = {
      secretKey: secretKey,
      publicKey: publicKey,
      publicKeyHash: publicKeyHash
    };

    return keystore;
  }

  protected generateKeyStore(mnemonic?: any): InMemorySigner {
    const { generateMnemonic } = KeyStoreUtils;

    const seed = mnemonicToSeedSync(mnemonic || generateMnemonic());
    const key = b58cencode(seed.slice(0, 32), prefix.edsk2);
    const signer = new InMemorySigner(key);

    return signer;
  }

  async createKeystore(mnemonic?: any): Promise<Keystore> {
    const signer = this.generateKeyStore(mnemonic);
    return await this.formatKeystore(signer);
  }

  async getSigner(secret_key: string) {
    const signer = await InMemorySigner.fromSecretKey(secret_key);
    return signer;
  }

  async getContract(contractAddress: string): Promise<any> {
    const contract = await this.taquitoClient.contract.at(contractAddress);
    return contract;
  }

  async getContractStorageType(contractAddress: string): Promise<any> {
    const contract = await this.getContract(contractAddress);
    return contract.script.code[0]["args"][0];
  }

  async setProvider(secret_key: string) {
    const signer = await this.getSigner(secret_key);

    this.taquitoClient.setProvider({ signer });
  }

  async contractParams({
    contractAddress,
    parameters,
    keystore,
    entrypoint
  }: CallInterface) {
    await this.setProvider(keystore.secretKey);
    const contract = await this.getContract(contractAddress);
    const transactionParams = await contract.methods[entrypoint](
      ...parameters
    ).toTransferParams({
      source: keystore.publicKeyHash,
      storageLimit: 1000,
      gasLimit: 1000000,
      amount: 0,
      fee: 0
    });
    return transactionParams;
  }

  async callContract({
    contractAddress,
    parameters,
    keystore,
    entrypoint
  }: CallInterface) {
    const transactionParams = await this.contractParams({
      contractAddress,
      parameters,
      keystore,
      entrypoint
    });
    const transaction = await this.taquitoClient.contract.transfer(
      transactionParams
    );
    return transaction;
  }

  async dryRunContract({
    contractAddress,
    parameters,
    keystore,
    entrypoint
  }: CallInterface) {
    const transactionParams = await this.contractParams({
      contractAddress,
      parameters,
      keystore,
      entrypoint
    });
    const transaction = await this.taquitoClient.dryRun.transfer(
      transactionParams
    );
    return transaction;
  }

  async call(params: CallInterface): Promise<any> {
    const transaction = await this.callContract(params);
    return transaction;
  }

  async dryRun(params: CallInterface): Promise<any> {
    const transaction = await this.dryRunContract(params);
    return transaction;
  }

  async pack(params: PackInterface) {
    const { payload, payloadType } = params;
    const result = await this.rpcInstance.packData({
      data: payload,
      type: payloadType
    });
    return result.packed;
  }

  async sign({ payload, secretKey }: SignInterface) {
    const signer = await this.getSigner(secretKey);
    const payloadSignature = await signer.sign(payload);
    return payloadSignature.sig;
  }

  base64Key(key: string): string {
    const keyPrefix = key.substr(0, 4);
    if (!isValidPrefix(keyPrefix)) {
      throw new Error("key contains invalid prefix");
    }

    const b58Key = new Buffer(b58cdecode(key, prefix[keyPrefix]));
    return b58Key.toString("base64");
  }
}
export const blockchainOperator = BlockchainOperator.getInstance();
