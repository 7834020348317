







import { Vue, Component } from "vue-property-decorator";
import {
  getEmailVerificationToken,
  verifyEmail
} from "@/services/apiClient/pracOnboarding";

@Component
export default class EmailVerification extends Vue {
  hasError = false;
  isLoading = false;
  supportEmail = "mailto:";

  async mounted() {
    this.setSupportEmail();
    await this.verifyEmail();
  }

  private async verifyEmail() {
    try {
      this.isLoading = true;

      const emailVerificationToken = getEmailVerificationToken(this.$route);

      if (emailVerificationToken != undefined) {
        await verifyEmail(emailVerificationToken);
      }
    } catch (e) {
      this.handleError(e);
    } finally {
      this.isLoading = false;
    }
  }

  private handleError(e: any) {
    if (e.response.data && e.response.data["errors"]) {
      const isAlreadyConfirmed = e.response.data["errors"].some(
        (error: any) => error.key == "email_already_confirmed"
      );
      this.hasError = !isAlreadyConfirmed;
    } else {
      this.hasError = true;
    }
  }

  private setSupportEmail() {
    const isDefaultLocale = this.$i18n.locale == "fr";
    this.supportEmail += isDefaultLocale ? "praticien" : "practitioner";
    this.supportEmail += "@moneytrack.io";
  }
}
