















import { practitionerLocale } from "@/services/pracOnboarding/dataStorage";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class FileType extends Vue {
  @Prop() documents!: any;
  @Prop() currentIndex!: number;
  @Prop() selected: boolean | undefined;
  @Prop() file!: { name: string; icon: string };

  icons = {
    passport: require(`@/assets/icons/passport.svg`),
    "identity-card": require(`@/assets/icons/identity-card.svg`),
    "driving-license": require(`@/assets/icons/driving-license.svg`),
    kbis: require(`@/assets/icons/file.svg`),
    sirene: require(`@/assets/icons/file.svg`),
    urssaf: require(`@/assets/icons/file.svg`),
    adeli: require(`@/assets/icons/file.svg`),
    legal_document_es_1: require(`@/assets/icons/file.svg`),
    legal_document_es_2: require(`@/assets/icons/file.svg`),
    legal_document_pt: require(`@/assets/icons/file.svg`),
    legal_document_it: require(`@/assets/icons/file.svg`),
    legal_document_be: require(`@/assets/icons/file.svg`),
    legal_document_nl: require(`@/assets/icons/file.svg`),
    legal_document_de: require(`@/assets/icons/file.svg`),
    legal_document_lu: require(`@/assets/icons/file.svg`),
    legal_document_ie: require(`@/assets/icons/file.svg`),
    legal_document_at: require(`@/assets/icons/file.svg`),
    articles_of_association_fr: require(`@/assets/icons/file.svg`),
    articles_of_association_es: require(`@/assets/icons/file.svg`),
    articles_of_association_pt: require(`@/assets/icons/file.svg`),
    articles_of_association_it: require(`@/assets/icons/file.svg`),
    articles_of_association_nl: require(`@/assets/icons/file.svg`),
    articles_of_association_be: require(`@/assets/icons/file.svg`),
    articles_of_association_de: require(`@/assets/icons/file.svg`),
    articles_of_association_lu: require(`@/assets/icons/file.svg`),
    articles_of_association_ie: require(`@/assets/icons/file.svg`),
    articles_of_association_at: require(`@/assets/icons/file.svg`),
    shareholder_declaration: require(`@/assets/icons/file.svg`)
  };

  async mounted() {
    this.$i18n.locale = await practitionerLocale();    
  }

  get documentType() {
    return this.documents[this.currentIndex].type.replace(/(medical_center|independent)\./, "");
  }
}
