
































import { Vue, Component } from "vue-property-decorator";
import Input from "@/components/shared/Input.vue";
import Loader from "@/components/shared/Loader.vue";
import { submitBankInformations } from "@/services/apiClient/pracOnboarding";
import "@/filters/filters.ts";
import { practitionerLocale } from "@/services/pracOnboarding/dataStorage";

@Component({
  components: {
    Input,
    Loader
  }
})
export default class BankInformationsForm extends Vue {
  name = "";
  iban = "";
  bic = "";
  errorMessage = "";
  isSubmitting = false;

  async mounted() {
    this.$i18n.locale = await practitionerLocale();
  }

  async submitForm() {
    this.errorMessage = "";
    this.isSubmitting = true;

    try {
      await submitBankInformations(this.name, this.iban, this.bic);
      this.$emit("modal", parseInt(this.$attrs.currentIndex));
    } catch (_) {
      this.isSubmitting = false;
      this.errorMessage = this.$t("i18n.check_inputs") as string;
    }
  }
}
