
































import { Vue, Component, Prop } from "vue-property-decorator";
import FileType from "@/components/pracOnboarding/FileType.vue";
import CustomPageInput from "@/components/pracOnboarding/CustomPageInput.vue";
import { KycDocument } from "@/services/pracOnboarding/kycDocuments";
import { submitDocuments } from "@/services/apiClient/pracOnboarding";
import { practitionerLocale } from "@/services/pracOnboarding/dataStorage";

@Component({
  components: {
    CustomPageInput,
    FileType
  }
})
export default class FileUpload extends Vue {
  @Prop() documents!: KycDocument[];
  @Prop() currentIndex!: number;

  hasFailed = false;
  errorMessage = "";
  isMultiplePages = false;

  async submitDocument() {
    const formData = new FormData();
    const currentDocument = this.documents[this.currentIndex];

    formData.append("document_type", currentDocument.label);

    if (currentDocument.label != "bank_informations") {
      currentDocument.pages?.forEach(page => {
        formData.append("pages[]", page);
      });
    }
    try {
      await submitDocuments(formData);
      currentDocument.uploaded = true;
      this.$emit("modal");
    } catch (e) {
      this.hasFailed = true;
      this.errorMessage = e.response.data["errors"][0]["message"];
    }
  }

  async mount() {
    this.$i18n.locale = await practitionerLocale();
  }

  created() {
    const multiplePagesDocs = ["identity-card", "driving-license"];
    const { type: documentType } = this.documents[this.currentIndex];

    this.isMultiplePages = multiplePagesDocs.includes(documentType!);
  }
}
