import Vue from "vue";
import insertSpacesInString from "@/services/utils/insertSpacesInString";
import { friendlyFormatIBAN } from "ibantools";

Vue.filter("showCurrency", (val: string) => val + "\u00A0€");
Vue.filter("formatCard", (value: string) => {
  const chunks = value.replace(/X/g, ".").match(/(.{4})/g);
  return chunks ? chunks.join(" ") : value;
});
Vue.filter("formatPhone", (value: string) => {
  const human_number = value.replace(/^\+33/, "0");
  return insertSpacesInString(human_number, 2);
});
Vue.filter("formatLastname", (value: string) => value.toUpperCase());
Vue.filter("hideCard", (value: string) => {
  if (value) {
    const formattedNumber = "*".repeat(12) + value.substr(12, 15);
    return formattedNumber.split(/(.{4})/).join(" ");
  }
});
Vue.filter("narrowDateFormat", (date: Date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const formattedDay = day < 10 ? "0" + day : day;
  const formattedMonth = month < 10 ? "0" + month : month;
  return `${formattedDay}/${formattedMonth}/${date.getFullYear()}`;
});

Vue.filter(
  "time",
  (date: Date) =>
    `${date.getHours()}:${(date.getMinutes() < 10 ? "0" : "") +
      date.getMinutes()}`
);
Vue.filter(
  "formattedTimer",
  (seconds: number) =>
    `${Math.floor(seconds / 60)}:${(seconds % 60 < 10 ? "0" : "") +
      (seconds % 60)}`
);
Vue.filter("expirationDate", (date: string) => {
  date.replace("/", "");
  return date.length >= 2 && date.match(/^\d+$/)
    ? date.slice(0, 2) + "/" + date.slice(2)
    : date;
});

Vue.filter("customDateFormat", (date: Date) => {
  return (
    ("00" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    ("00" + date.getDate()).slice(-2) +
    "/" +
    date.getFullYear() +
    " " +
    ("00" + date.getHours()).slice(-2) +
    ":" +
    ("00" + date.getMinutes()).slice(-2)
  );
});

Vue.filter("ibanFormat", (input: string) => {
  return friendlyFormatIBAN(input);
});
