export function getCurrentEnv() {
  const currentEnv = localStorage.getItem("selectedEnv");

  if (currentEnv) {
    return currentEnv as string;
  } else if (process.env.VUE_APP_DEFAULT_ENV) {
    return (process.env.VUE_APP_DEFAULT_ENV as string).toUpperCase();
  } else {
    return process.env.VUE_APP_ENVIRONMENT as string;
  }
}

export function getEnvVariable(key: string) {
  const currentEnv = getCurrentEnv();
  const targetValue = process.env[`${key}_${currentEnv}`];
  if (currentEnv && targetValue) return targetValue as string;
  return process.env[key] as string;
}
