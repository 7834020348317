import fileType from "file-type";
import HandledError from "@/services/errors/HandledError";

const convertDataURIToBinary = (dataURI: string) => {
  let base64Index = dataURI.indexOf(";base64,") + ";base64,".length;
  let base64 = dataURI.substring(base64Index);
  let raw = window.atob(base64);
  let rawLength = raw.length;
  let array = new Uint8Array(new ArrayBuffer(rawLength));

  for (let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
};

const checkFileType = async (result: string, allowedTypes: string[]) => {
  const binaryData = convertDataURIToBinary(result);
  const type = await fileType.fromBuffer(binaryData);
  return type && allowedTypes.includes(type.mime) ? true : false;
};

export const dataURItoBlob = (dataURI: string, mime: string) => {
  var byteString, buffer, u8arr;

  byteString = atob(dataURI.split(",")[1]);
  buffer = new ArrayBuffer(byteString.length);
  u8arr = new Uint8Array(buffer);

  for (var i = 0; i < byteString.length; i++) {
    u8arr[i] = byteString.charCodeAt(i);
  }
  return new Blob([buffer], { type: mime });
};

export const uploadFile = (file: File, allowedTypes: string[]) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      const isfileValid = await checkFileType(
        reader.result as string,
        allowedTypes
      );
      isfileValid ? resolve(file) : reject(new HandledError("Wrong file type"));
    };
    reader.onerror = error => reject(error);
  });
};
