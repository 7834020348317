export const Locales = ["en", "fr"];
export type LocaleType = "en" | "fr";
export type CountryType =
  | "fr"
  | "es"
  | "pt"
  | "be"
  | "nl"
  | "it"
  | "de"
  | "lu"
  | "ie"
  | "at";

export type CompanyKind = "independent" | "medical_center"


export type KycDocument = {
  label: string;
  allowed_types?: { [key: string]: { [key: string]: string[] } };
  type?: string;
  refused_reason?: string;
  refused_reason_details?: string[];
  pages?: (File | Blob)[];
  uploaded: boolean;
  disclaimers?: { [key: string]: { [key: string]: string[] } };
};

export const bankInformations = {
  disclaimers: {
    fr: { independent: ["iban_bic"], medical_center: ["iban_bic"] },
    es: { independent: ["iban_bic"], medical_center: ["iban_bic"] },
    pt: { independent: ["iban_bic"], medical_center: ["iban_bic"] },
    it: { independent: ["iban_bic"], medical_center: ["iban_bic"] },
    be: { independent: ["iban_bic"], medical_center: ["iban_bic"] },
    nl: { independent: ["iban_bic"], medical_center: ["iban_bic"] },
    de: { independent: ["iban_bic"], medical_center: ["iban_bic"] },
    lu: { independent: ["iban_bic"], medical_center: ["iban_bic"] },
    ie: { independent: ["iban_bic"], medical_center: ["iban_bic"] },
    at: { independent: ["iban_bic"], medical_center: ["iban_bic"] }
  },
  label: "bank_informations",
  type: "",
  uploaded: false
};

export const identityProofDisclaimer = [ "identity_card", "recto_verso", "passport", "signature_and_photo", "driving_license", "recto_verso" ];
export const identityProofAllowedTypes = [ "identity-card", "passport", "driving-license" ];

export const identityProof = {
  disclaimers: {
    fr: { independent: identityProofDisclaimer, medical_center: identityProofDisclaimer },
    es: { independent: identityProofDisclaimer, medical_center: identityProofDisclaimer },
    pt: { independent: identityProofDisclaimer, medical_center: identityProofDisclaimer },
    be: { independent: identityProofDisclaimer, medical_center: identityProofDisclaimer },
    nl: { independent: identityProofDisclaimer, medical_center: identityProofDisclaimer },
    it: { independent: identityProofDisclaimer, medical_center: identityProofDisclaimer },
    de: { independent: identityProofDisclaimer, medical_center: identityProofDisclaimer },
    lu: { independent: identityProofDisclaimer, medical_center: identityProofDisclaimer },
    ie: { independent: identityProofDisclaimer, medical_center: identityProofDisclaimer },
    at: { independent: identityProofDisclaimer, medical_center: identityProofDisclaimer }
  },
  label: "identity_proof",
  allowed_types: {
    fr: { independent: identityProofAllowedTypes, medical_center: identityProofAllowedTypes },
    es: { independent: identityProofAllowedTypes, medical_center: identityProofAllowedTypes },
    pt: { independent: identityProofAllowedTypes, medical_center: identityProofAllowedTypes },
    be: { independent: identityProofAllowedTypes, medical_center: identityProofAllowedTypes },
    it: { independent: identityProofAllowedTypes, medical_center: identityProofAllowedTypes },
    nl: { independent: identityProofAllowedTypes, medical_center: identityProofAllowedTypes },
    de: { independent: identityProofAllowedTypes, medical_center: identityProofAllowedTypes },
    lu: { independent: identityProofAllowedTypes, medical_center: identityProofAllowedTypes },
    ie: { independent: identityProofAllowedTypes, medical_center: identityProofAllowedTypes },
    at: { independent: identityProofAllowedTypes, medical_center: identityProofAllowedTypes }
  },
  type: "",
  refused_reason: "",
  refused_reason_details: [],
  pages: [],
  uploaded: false
};

export const registrationProofAllowedTypes = {
  fr: { independent: ["kbis", "sirene", "urssaf", "adeli"], medical_center: ["kbis"] },
  es: { independent: ["independent.legal_document_es_1", "independent.legal_document_es_2"], 
        medical_center: ["medical_center.legal_document_es_1", "medical_center.legal_document_es_2"] },
  pt: { independent: ["independent.legal_document_pt"], medical_center: ["medical_center.legal_document_pt"] },
  it: { independent: ["independent.legal_document_it"], medical_center: ["medical_center.legal_document_it"] },
  nl: { independent: ["independent.legal_document_nl"], medical_center: ["medical_center.legal_document_nl"] },
  be: { independent: ["independent.legal_document_be"], medical_center: ["medical_center.legal_document_be"] },
  de: { independent: ["independent.legal_document_de"], medical_center: ["medical_center.legal_document_de"] },
  lu: { independent: ["independent.legal_document_lu"], medical_center: ["medical_center.legal_document_lu"] },
  ie: { independent: ["independent.legal_document_ie"], medical_center: ["medical_center.legal_document_ie"] },
  at: { independent: ["independent.legal_document_at"], medical_center: ["medical_center.legal_document_at"] }
};

export const registrationProof = {
  disclaimers: registrationProofAllowedTypes,
  label: "registration_proof",
  allowed_types: registrationProofAllowedTypes,
  type: "",
  refused_reason: "",
  pages: [],
  uploaded: false
};

export const articlesOfAssociationAllowedTypes = {
  fr: { medical_center: ["articles_of_association_fr"], independent: [] },
  es: { medical_center: ["articles_of_association_es"], independent: [] },
  pt: { medical_center: ["articles_of_association_pt"], independent: [] },
  it: { medical_center: ["articles_of_association_it"], independent: [] },
  nl: { medical_center: ["articles_of_association_nl"], independent: [] },
  be: { medical_center: ["articles_of_association_be"], independent: [] },
  de: { medical_center: ["articles_of_association_de"], independent: [] },
  lu: { medical_center: ["articles_of_association_lu"], independent: [] },
  ie: { medical_center: ["articles_of_association_ie"], independent: [] },
  at: { medical_center: ["articles_of_association_at"], independent: [] }
};

export const articlesOfAssociation = {
  disclaimers: articlesOfAssociationAllowedTypes,
  label: "articles_of_association",
  allowed_types: articlesOfAssociationAllowedTypes,
  type: "",
  refused_reason: "",
  pages: [],
  uploaded: false
};

export const shareholderDeclarationAllowedTypes = {
  fr: { medical_center: ["shareholder_declaration"], independent: [] },
  es: { medical_center: ["shareholder_declaration"], independent: [] },
  pt: { medical_center: ["shareholder_declaration"], independent: [] },
  it: { medical_center: ["shareholder_declaration"], independent: [] },
  nl: { medical_center: ["shareholder_declaration"], independent: [] },
  be: { medical_center: ["shareholder_declaration"], independent: [] },
  de: { medical_center: ["shareholder_declaration"], independent: [] },
  lu: { medical_center: ["shareholder_declaration"], independent: [] },
  ie: { medical_center: ["shareholder_declaration"], independent: [] },
  at: { medical_center: ["shareholder_declaration"], independent: [] }
};

export const shareholderDeclaration = {
  disclaimers: shareholderDeclarationAllowedTypes,
  label: "shareholder_declaration",
  allowed_types: shareholderDeclarationAllowedTypes,
  type: "",
  refused_reason: "",
  pages: [],
  uploaded: false
};

export const kycDocumentsTemplate = [
  bankInformations,
  identityProof,
  registrationProof,
  articlesOfAssociation,
  shareholderDeclaration
];
