































































import { Vue, Component } from "vue-property-decorator";
import {
  bankInformation,
  documentsState,
  practitionerLocale,
} from "@/services/pracOnboarding/dataStorage";

@Component
export default class Success extends Vue {
  isLoading = false;
  bankAccount = {};
  documents: Array<any> = [];

  async mounted() {
    this.$i18n.locale = await practitionerLocale();
    this.isLoading = true;
    try {
      this.bankAccount = await bankInformation({ forceFetch: true });
      this.documents = await documentsState({ forceFetch: true });
    } catch (error) {
      this.$router.push({ name: "Error" });
    } finally {
      this.isLoading = false;
    }
  }

  isDocumentStatusValid(status: string): boolean {
    return ["validation_asked", "exported"].includes(status);
  }

  redirectToUploadForm() {
    this.$router.push({ name: "UploadForm" });
  }

  get kyc_documents(): Array<any> {
    return this.documents;
  }
}
