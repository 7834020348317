const regexValidators = {
  adeli: /^[A-Z0-9]+$/,
  phone: /^(((\+)33|0)[1-9](\d{2}){4})(\+\w+)?$/,
  mobile: /^(((\+)33|0)[6-7](\d{2}){4})(\+\w+)?$/,
  website: /^https?:\/\/[^ "]+$/,
  doctolib: /^https:\/\/www.doctolib\.fr/,
  email: /^\S+@\S+\.\S+$/,
  name: /^[A-Za-zçéèêïÇÉÈÊÏ\-\s]{3,}$/,
  keyChangeToken: /^[0-9]{6}$/
};

export const birthdateValidation = (date: string) => {
  const birthDate = new Date(date);
  let currentDate = new Date();

  currentDate.setFullYear(currentDate.getFullYear() - 18);
  return currentDate >= birthDate;
};

export type FieldType =
  | "adeli"
  | "phone"
  | "mobile"
  | "website"
  | "doctolib"
  | "email"
  | "name"
  | "keyChangeToken";

export const validateField = (type: FieldType, value: string): boolean => {
  return regexValidators[type].test(value);
};

export const validateFileSize = (
  file: File | Blob,
  fileSizeLimit: number
): boolean => {
  return file.size <= fileSizeLimit;
};
