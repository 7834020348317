










































import { Vue, Component, Prop } from "vue-property-decorator";
import { uploadFile } from "@/services/utils/uploadFile";
import { validateFileSize } from "@/services/validators/validators";
import { practitionerLocale } from "@/services/pracOnboarding/dataStorage";

const FILE_SIZE_LIMIT = 7 * 1000000;

type FileUpload = {
  name: string;
  type: string;
  page: File | null;
  uploaded: boolean;
  errors: { format: boolean; size: boolean };
};

@Component
export default class CustomPageInput extends Vue {
  @Prop() documents: any;
  @Prop() currentIndex!: number;
  @Prop() id!: number;
  @Prop() placeholder: string | undefined;

  file: FileUpload = {
    name: "",
    type: "",
    page: null,
    uploaded: false,
    errors: {
      format: false,
      size: false
    }
  };

  async mounted() {
    this.$i18n.locale = await practitionerLocale();
  }

  async processFile(e: any) {
    this.file.errors = { format: false, size: false };
    const file = e.target.files[0];

    if (!validateFileSize(file, FILE_SIZE_LIMIT)) {
      this.file.errors.size = true;
      this.resetFile();
      return;
    }

    try {
      const res = await uploadFile(file, this.allowedTypes);
      const multiplePages = ["identity-card", "driving-license"].includes(
        this.documents[this.currentIndex].type
      );
      this.file.page = res as File;
      this.file.name = (res as File).name;
      this.file.uploaded = true;

      if (multiplePages) {
        if (this.documents[this.currentIndex].pages.length == 1) {
          this.documents[this.currentIndex].pages[1] = res;
        } else {
          this.documents[this.currentIndex].pages[0] = res;
        }
      } else {
        this.documents[this.currentIndex].pages[0] = res;
      }
    } catch (error) {
      this.file.errors.format = true;
      this.resetFile();
    }
  }

  resetFile() {
    this.file.name = "";
    this.file.uploaded = false;
    this.file.page = null;
    this.documents[this.currentIndex].uploaded = false;
  }

  get allowedTypes() {
    return ["image/jpeg", "image/jpg", "image/png", "application/pdf"];
  }

  get isError() {
    return this.file.errors.format || this.file.errors.size;
  }
}
