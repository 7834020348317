
































import { practitionerLocale } from "@/services/pracOnboarding/dataStorage";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class FileInput extends Vue {
  @Prop() title: string | undefined;
  @Prop() disclaimers: string[] | undefined;
  @Prop() iconName: string | undefined;
  @Prop() isDocumentUploaded: boolean | undefined;
  @Prop() hasError: boolean | undefined;
  @Prop() refusedReason: string | undefined;
  @Prop() refusedReasonDetails: string[] | undefined;

  async mounted() {
    this.$i18n.locale = await practitionerLocale();
  }

  get isInErrorInitialState() {
    return this.hasError && !this.isDocumentUploaded;
  }

  get mayDocumentBeUploaded() {
    return !this.isDocumentUploaded || this.hasError;
  }

  async clickHandler() {
    if (this.mayDocumentBeUploaded) {
      await this.$emit("toggle");
    }
  }
}
