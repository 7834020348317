import Vue from "vue";
import PracOnboardingApp from "./PracOnboardingApp.vue";
import router from "./router/pracOnboarding";
import i18n from "@/i18n/main";

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: h => h(PracOnboardingApp)
}).$mount("#app");
