
















import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class Input extends Vue {
  @Prop() readonly placeholder: string | undefined;
  @Prop() readonly value: string | undefined;
  @Prop() readonly error: boolean | undefined;
  @Prop() readonly type: string | undefined;
  @Prop() readonly errorMessage: string | undefined;

  focus() {
    const el = this.$refs.input as HTMLInputElement;
    if (this.type) el.type = this.type;
    el.focus();
  }

  focusNextElement(event: any) {
    if (!this.errorMessage && !this.error) {
      const currentElement = event.currentTarget;
      const nextSiblingElement = event.target.parentElement.nextSibling;
      const nextSiblingType = nextSiblingElement.nodeName;

      if (nextSiblingType == "LABEL") {
        nextSiblingElement.focus();
      } else {
        currentElement.blur();
      }
    }
  }

  beforeDestroy() {
    const el = this.$refs.input as HTMLInputElement;
    el.type = "";
  }
}
