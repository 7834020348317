export function isCordovaEnabled(): boolean {
  return process.env.VUE_APP_PLATFORM === "cordova";
}

export function cordovaWrapper(): any {
  const globalObject = window as any;
  return globalObject.cordova as any;
}

export function cordovaEmailHandler(email: string) {
  cordovaWrapper().InAppBrowser.open(
    "mailto:" + email,
    "_system",
    "location=yes"
  );
}

export function cordovaNotification(msg: string, buttonText: string) {
  const _navigator = navigator as any;
  _navigator.notification.alert(msg, () => {}, "", buttonText);
}

type NotificationTranslations = {
  successMsg: string;
  errorMsg: string;
  close: string;
};

export function cordovaSaveCanvasInGallery(
  canvasRef: HTMLCanvasElement,
  translations: NotificationTranslations
) {
  const _window = window as any;

  _window.canvas2ImagePlugin.saveImageDataToLibrary(
    () => cordovaNotification(translations.successMsg, translations.close),
    () => cordovaNotification(translations.errorMsg, translations.close),
    canvasRef
  );
}
