










































import { Vue, Component } from "vue-property-decorator";
import FileInput from "@/components/pracOnboarding/FileInput.vue";
import FileTypeSelect from "@/components/pracOnboarding/FileTypeSelect.vue";
import FileUpload from "@/components/pracOnboarding/FileUpload.vue";
import BankInformationsForm from "@/components/pracOnboarding/BankInformationsForm.vue";
import Modal from "@/components/pracOnboarding/Modal.vue";
import Loader from "@/components/shared/Loader.vue";
import * as PracStorage from "@/services/pracOnboarding/dataStorage";
import {
  CompanyKind,
  KycDocument,
} from "@/services/pracOnboarding/kycDocuments";

@Component({
  components: {
    FileInput,
    FileTypeSelect,
    FileUpload,
    BankInformationsForm,
    Modal,
    Loader,
  },
})
export default class UploadForm extends Vue {
  isModalVisible = false;
  currentIndex = 0;
  componentId = "";
  isLoading = false;
  hasError = false;
  country = "";
  documents: KycDocument[] = [];
  currentPractitioner: any = null;

  handleFileTypeSelection(documentType: string) {
    const currentDocument = this.findCurrentDocument(documentType);
    if (currentDocument) {
      this.resetDocumentProperties(currentDocument, documentType);
      this.updateComponent("FileUpload");
    }
  }

  findCurrentDocument(documentType: string): KycDocument | undefined {
    return this.documents.find(({ allowed_types }) => {
      if (allowed_types)
        return allowed_types![this.country][this.companyKind].includes(
          documentType
        );

      return false;
    });
  }

  resetDocumentProperties(document: KycDocument, documentType: string) {
    document.type = documentType;
    document.pages = [];
    document.uploaded = false;
  }

  handleModal(documentIndex?: number) {
    if (documentIndex != undefined) {
      if (documentIndex >= 0) {
        this.documents[documentIndex].uploaded = true;
      }
      this.isModalVisible = false;
    } else if (this.areAllDocumentsUploaded) {
      this.$router.push({ name: "Success" });
    } else {
      this.isModalVisible = false;
    }
  }

  toggleModalStatus(documentLabel: string, index: number) {
    this.currentIndex = index;
    this.renderDocumentLabelComponent(documentLabel);
    this.isModalVisible = true;
  }

  renderDocumentLabelComponent(documentLabel: string) {
    const component =
      documentLabel == "bank_informations"
        ? "BankInformationsForm"
        : "FileTypeSelect";
    this.updateComponent(component);
  }

  updateComponent(id: string) {
    this.componentId = id;
  }

  async setCurrentPracState() {
    this.currentPractitioner = await PracStorage.currentPractitioner({
      forceFetch: true,
    });
    this.country = await PracStorage.practitionerCountry();
    this.$i18n.locale = await PracStorage.practitionerLocale();
    this.documents = await PracStorage.documents({ forceFetch: true });

    if (
      this.bankAccount.uploaded &&
      this.currentPractitioner.status == "reviewing"
    ) {
      this.$router.push({
        name: "Success",
        query: { token: PracStorage.getToken() },
      });
    } else if (this.currentPractitioner.status == "refused") {
      this.hasError = true;
    }
  }

  async mounted() {
    PracStorage.setToken(this.$route);
    this.isLoading = true;
    try {
      await this.setCurrentPracState();
    } catch (error) {
      this.$router.push({ name: "Error" });
    } finally {
      this.isLoading = false;
    }
  }

  get bankAccount() {
    return this.documents.find((kycDocument) => {
      return kycDocument.label == "bank_informations";
    })!;
  }

  get title() {
    return !this.hasError ? this.$t("i18n.title") : this.$t("i18n.title-error");
  }

  get disclaimersKey() {
    return !this.hasError ? "i18n.disclaimer" : "i18n.disclaimer-error";
  }

  get areAllDocumentsUploaded() {
    return this.documents.every((doc) => doc.uploaded);
  }

  get companyKind(): CompanyKind {
    return this.currentPractitioner.company_kind;
  }

  get shareholderDeclarationDownloadLink(): string {
    return `/pdf/shareholder_declaration_${this.$i18n.locale}.pdf`;
  }
}
