import axios, { AxiosRequestConfig } from "axios";
import { sign } from "jwt-ed25519-tn";
import { Keystore } from "@/stores/types";
import { Guid } from "guid-typescript";
import * as Sentry from "@sentry/browser";
import { getEnvVariable } from "@/services/utils/environmentManager";
import handleError from "@/services/errors/handleError";
import MaintenanceError from "@/services/errors/MaintenanceError";
import { blockchainOperator } from "../blockchain/BlockchainOperator";

export type ApiRequestConfig = AxiosRequestConfig & {
  keystore?: Keystore;
  issuerType?: string;
  jwtToken?: string;
};

function generateJwtToken(keystore: Keystore, issuerType: string): string {
  const signParams = {
    key: blockchainOperator.base64Key(keystore.secretKey),
    algorithm: "ED25519"
  };

  const now = Date.now();
  const iat = Math.round(now / 1000);
  const exp = iat + 60;
  const aud = "all";
  const payload = {
    iss: issuerType,
    exp: exp,
    iat: iat,
    aud: aud
  };

  const headers = {
    keyid: keystore.publicKey
  };

  return sign(payload, signParams, headers);
}

const instance = axios.create({
  baseURL: `${getEnvVariable("VUE_APP_OS_URL")}/api/v1`
});

function authorizeConfig(config?: ApiRequestConfig): AxiosRequestConfig {
  let localConfig: ApiRequestConfig = { ...config };
  localConfig["headers"] = localConfig["headers"] || {};

  if (localConfig.keystore && localConfig.issuerType) {
    const jwtToken = apiClient.generateJwtToken(
      localConfig.keystore,
      localConfig.issuerType
    );
    localConfig.headers["Authorization"] = `Bearer ${jwtToken}`;
    localConfig.headers["X-Screen-Width"] = screen.width;
    localConfig.headers["X-Screen-Height"] = screen.height;
    localConfig.headers["X-Color-Depth"] = [
      1,
      4,
      8,
      15,
      16,
      24,
      32,
      48
    ].includes(screen.colorDepth)
      ? screen.colorDepth
      : 32;

    delete localConfig.keystore;
    delete localConfig.issuerType;
  }

  return localConfig;
}

function requestConfig(config?: ApiRequestConfig): AxiosRequestConfig {
  let localConfig = authorizeConfig(config);
  const transactionId = Guid.create().toString();

  Sentry.configureScope(scope => {
    scope.setTag("transaction_id", transactionId);
  });
  localConfig.headers["X-Request-Id"] = transactionId;

  return localConfig;
}

async function getRequest(url: string, config?: ApiRequestConfig) {
  return await throwHandledError(() =>
    instance.get(url, requestConfig(config))
  );
}

async function postRequest(url: string, data?: any, config?: ApiRequestConfig) {
  return await throwHandledError(() =>
    instance.post(url, data, requestConfig(config))
  );
}

async function patchRequest(
  url: string,
  data?: any,
  config?: ApiRequestConfig
) {
  return await throwHandledError(() =>
    instance.patch(url, data, requestConfig(config))
  );
}

async function putRequest(url: string, data?: any, config?: ApiRequestConfig) {
  return await throwHandledError(() =>
    instance.put(url, data, authorizeConfig(config))
  );
}

async function deleteRequest(url: string, config?: ApiRequestConfig) {
  return await throwHandledError(() =>
    instance.delete(url, requestConfig(config))
  );
}

async function throwHandledError(func: Function) {
  try {
    return await func();
  } catch (e) {
    const status = e.response && e.response.status;

    if (status == 503) {
      throw new MaintenanceError("Api is in maintenance");
    }
    if (status?.toString()?.startsWith("4")) handleError(e);
    throw e;
  }
}

const apiClient = {
  generateJwtToken,
  get: getRequest,
  post: postRequest,
  patch: patchRequest,
  put: putRequest,
  delete: deleteRequest,
  instance
};

export default apiClient;
