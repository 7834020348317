import { isCordovaEnabled } from "@/services/cordova";

const _window = window as any;
const isCordova = isCordovaEnabled();

function parseData(data: any) {
  return data ? JSON.parse(data) : "";
}

export const storageSet = (object: any, namespace: string, key: string) => {
  const formattedKey = namespace ? `${namespace}_${key}` : key;
  const data = JSON.stringify(object);

  return new Promise<void>((resolve, reject) => {
    if (isCordova) {
      _window.NativeStorage.setItem(formattedKey, data, resolve, reject);
    } else {
      localStorage.setItem(formattedKey, data);
      resolve();
    }
  });
};

export let storageGet = (namespace: string, key: string) => {
  const formattedKey = namespace ? `${namespace}_${key}` : key;
  return new Promise(resolve => {
    if (isCordova) {
      _window.NativeStorage.getItem(
        formattedKey,
        (data: any) => resolve(parseData(data)),
        () => resolve(undefined)
      );
    } else {
      const data = localStorage.getItem(formattedKey);
      resolve(parseData(data));
    }
  });
};

export const storageClear = () => {
  return new Promise<void>((resolve, reject) => {
    if (isCordova) {
      _window.NativeStorage.clear(resolve, reject);
    } else {
      localStorage.clear();
      resolve();
    }
  });
};

export const sessionStorageSet = (
  object: any,
  namespace: string,
  key: string
) => {
  const formattedKey = namespace ? `${namespace}_${key}` : key;
  const data = JSON.stringify(object);

  if (isCordova) {
    throw new Error("Method not implemented.");
  } else {
    sessionStorage.setItem(formattedKey, data);
  }
};

export const sessionStorageGet = (namespace: string, key: string) => {
  const formattedKey = namespace ? `${namespace}_${key}` : key;
  if (isCordova) {
    throw new Error("Method not implemented.");
  } else {
    const data = sessionStorage.getItem(formattedKey);
    return parseData(data);
  }
};

export const sessionStorageClear = () => {
  if (isCordova) {
    throw new Error("Method not implemented.");
  } else {
    sessionStorage.clear();
  }
};
