








import Vue from "vue";
import Component from "vue-class-component";
import Header from "@/components/shared/header/Header.vue";
import { setToken } from "./services/pracOnboarding/dataStorage";

@Component({
  components: {
    Header
  }
})
export default class PracOnboardingApp extends Vue {
  async created() {
    setToken(this.$route);
  }
}
