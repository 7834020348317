











import { Vue, Component } from "vue-property-decorator";
import FileType from "@/components/pracOnboarding/FileType.vue";
import {
  articlesOfAssociationAllowedTypes,
  CompanyKind,
  CountryType,
  registrationProofAllowedTypes,
  shareholderDeclarationAllowedTypes
} from "@/services/pracOnboarding/kycDocuments";
import * as PracStorage from "@/services/pracOnboarding/dataStorage";

type KycDocumentInfo = { name: string; icon: string };

@Component({
  components: {
    FileType
  }
})
export default class FileTypeSelect extends Vue {
  files: KycDocumentInfo[] = [];
  country: CountryType | null = null;
  currentPractitioner: any = null;

  async mounted() {
    this.currentPractitioner = await PracStorage.currentPractitioner();
    this.country = await PracStorage.practitionerCountry();
    this.$i18n.locale = await PracStorage.practitionerLocale();

    switch (this.$attrs.documentLabel) {
      case "identity_proof":
        this.files = this.identityProofDocumentsInfo;
        break;
      case "registration_proof":
        this.files = this.registrationProofDocumentsInfo;
        break;
      case "articles_of_association":
        this.files = this.articlesOfAssociationInfo;
        break;
      case "shareholder_declaration":
        this.files = this.shareholderDeclarationInfo;
        break;
    }
  }

  _buildDocumentsInfo(documentsList: any[]) {
    return documentsList.map(document => {
      return {
        name: document,
        icon: require(`@/assets/icons/file.svg`)
      };
    });
  }

  get articlesOfAssociationInfo() {
    return this._buildDocumentsInfo(articlesOfAssociationAllowedTypes[this.country!][this.company_kind]);
  }

  get shareholderDeclarationInfo() {
    return this._buildDocumentsInfo(shareholderDeclarationAllowedTypes[this.country!][this.company_kind]);
  }

  get registrationProofDocumentsInfo() {
    const types = registrationProofAllowedTypes[this.country!][this.company_kind] as string[];

    return this._buildDocumentsInfo(types);
  }

  get identityProofDocumentsInfo() {
    return [
      {
        name: "identity-card",
        icon: require(`@/assets/icons/identity-card.svg`)
      },
      {
        name: "passport",
        icon: require(`@/assets/icons/passport.svg`)
      },
      {
        name: "driving-license",
        icon: require(`@/assets/icons/driving-license.svg`)
      }
    ];
  }

  get company_kind(): CompanyKind {
    return this.currentPractitioner.company_kind;
  }
} 
