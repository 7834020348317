import Vue from "vue";
import VueRouter from "vue-router";
import UploadForm from "@/views/pracOnboarding/UploadForm.vue";
import EmailVerification from "@/views/pracOnboarding/EmailVerification.vue";
import Success from "@/views/pracOnboarding/Success.vue";

// not use VueRouter in test env
if (!process || process.env.NODE_ENV !== "test") {
  Vue.use(VueRouter);
}

const routes = [
  {
    path: "/kyc/document",
    name: "UploadForm",
    component: UploadForm
  },
  {
    path: "/email/verification",
    name: "EmailVerification",
    component: EmailVerification
  },
  {
    path: "/success",
    name: "Success",
    component: Success
  }
];

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: routes
});

export default router;
