import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const dateTimeFormats = {
  fr: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric"
    },
    narrow: {
      month: "short",
      day: "numeric"
    }
  }
};

function loadLocaleMessages() {
  const locales = require.context(
    "@/locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = <any>{};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = <any>{};
      messages[locale]["i18n"] = locales(key);
    }
  });
  return messages;
}

export default new VueI18n({
  fallbackLocale: "fr",
  locale: "fr",
  dateTimeFormats,
  messages: loadLocaleMessages()
});
